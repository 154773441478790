<template>
    <rotating-update-icon v-if="showLoader" />
    <div v-else class="table-cell-serp-features">
        <span
            v-if="isEmpty"
            class="empty-value"
            >&mdash;</span
        >
        <div
            v-for="(active, feature) in getSorted"
            :key="feature"
            :data-important-tooltip="$t(`serp-feature-${feature}`)"
            :class="`kw-sf-icon kw-sf-icon-${feature} ${
                active ? 'active' : ''
            }`"
        ></div>
        <div
            v-if="getOthersCount"
            class="kw-sf-number"
            :data-important-tooltip="getOthers"
        >
            +{{ getOthersCount }}
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RotatingUpdateIcon from '@/components/ui-elements/RotatingUpdateIcon';
export default {
    name: 'SerpFeaturesRenderer',
    components: { RotatingUpdateIcon },
    data() {
        return {
            maxCount: 5,
        };
    },
    computed: {
        ...mapGetters(['getIsRefreshRunning','getCurrentRefreshingKeywords']),
        getCount() {
            return Object.keys(this.params.data.serp_features).length;
        },
        isEmpty() {
            return this.getCount == 0;
        },
        getOthersCount() {
            return Math.max(this.getCount - this.maxCount, 0);
        },
        getSorted() {
            const {ai, ...rest} = this.params.data.serp_features;

            const sortedKeys = Object.keys(rest).sort((a, b) => {
                if (rest[a] === rest[b]) {
                    return 0;
                }
                return rest[a] ? -1 : 1;
            });

            if('ai' in this.params.data.serp_features){
                sortedKeys.unshift('ai')
            }
            
            const sortedObject = {};
            let count = 0;
            sortedKeys.forEach(key => {
                if (count < this.maxCount) {
                    sortedObject[key] = this.params.data.serp_features[key];
                    count++;
                }
            });

            return sortedObject;
        },
        getOthers() {
            let sortedKeys = Object.keys(this.params.data.serp_features).sort((a, b) => {
                if (this.params.data.serp_features[a] === this.params.data.serp_features[b]) {
                    return 0;
                }

                return this.params.data.serp_features[a] ? -1 : 1;
            });

            return sortedKeys
                .slice(this.maxCount)
                .map(feature => this.$t(`serp-feature-${feature}`))
                .join(',');
        },
        showLoader(){
            return this.getIsRefreshRunning && this.getCurrentRefreshingKeywords.includes(this.params.data.id);
        }
    },
};
</script>

<style lang="scss" scoped>
.table-cell-serp-features {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2px;
    height: 100%;
    overflow: hidden;
}
.empty-value {
    color: #d3d3d3;
}

.kw-sf-number {
    color: kw-color(kw-blue);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}
</style>
